import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM:`}</p>
    <p>{`Odd Minutes, 5-Strict HSPU’s`}</p>
    <p>{`Even Minutes, 5-Kipping HSPU’s`}</p>
    <p>{`rest, then:`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Rogue Pump-N-Row Challenge`}</em></strong></p>
    <p>{`2000M Row for time followed by max bench press reps. Each successful rep
completed deducts :10 off your row time. RX Men = Bodyweight Bench, RX
Women = 75% bodyweight. Scaled Men/Women = 50% bodyweight.`}</p>
    <p><em parentName="p">{`*`}{`1:00 rest between row and start of bench press.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      